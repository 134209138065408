<template>
  <b-row>
    <b-col cols="12">
      <BaseCard title="Il mio profilo">
        <template v-slot:comcode>
          <b-row>
            <b-col cols="12" lg="6" class="mb-3">
              <h6>Name</h6>
              <b-form-input
                  v-model="user.user.authinfo.name"
                  placeholder="Inserisci Nome"
                  name="name-group"
                  size="lg"
              ></b-form-input>
            </b-col>
            <b-col cols="12" lg="6" class="mb-3">
              <h6>Email</h6>
              <b-form-input
                  v-model="user.user.authinfo.email"
                  placeholder="Inserisci email"
                  name="name-group"
                  size="lg"
              ></b-form-input>
            </b-col>
            <b-col cols="12" lg="6" class="mb-3">
              <h6>Modifica Password</h6>
              <b-form-input
                  v-model="user.user.authinfo.password"
                  placeholder="Inserisci nuova password"
                  name="name-group"
                  size="lg"
              ></b-form-input>
            </b-col>

            <b-col cols="12" lg="12" class="text-right">
              <b-button variant="success" @click="save">
                Salva
                <i class="mdi mdi-check ml-1"></i>
              </b-button>
            </b-col>
          </b-row>
        </template>
      </BaseCard>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "UsersProfile",
  components: {
    BaseCard: () => import("@/components/card/BaseCard"),
  },
  computed: {
    user() {
      return this.$store.state.authentication
    }
  },
  methods: {
    async save() {
      const res = await this.axios.post('user/'+this.user.user_rule, {
        id: this.user.user.id,
        user_id: this.user.user.user_id,
        authinfo: this.user.user.authinfo
      })

      this.$swal.fire({
        title: 'Salvataggio Utente',
        text: res.data.message,
        icon: res.data.status,
        confirmButtonColor: '#1f2335',
      })
    }
  }
}
</script>

<style scoped>

</style>